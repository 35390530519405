import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Minislider from "../components/minislider";
import scheme from "../images/svg/scheme.svg";
import Svgsprite from "../components/svgsprite";
import Youtube from "../components/youtube";
import Orderform from "../components/orderform";

const IndexPage = () => (
  <Layout>
    <SEO title="For Individuals" />
    <section data-side="boatGod" className="contentbox wrap --fullw">
      <div className="textcontent">
        <h2>
          BoatGod module <span>for yachts owners</span>
        </h2>
        <p>
          We are delighted to introduce you a BoatGod module from BoatPilot.
          Well established in the charter business, now this module is also
          available to private yacht owners. BoatGod is a universal onboard
          router, primarily for telemetry of all existing yacht systems. The
          main functions are multiplexing data from the NMEA network and
          additional sensors to mobile devices connected locally via the WiFi or
          remotely via the Internet.
        </p>

        <h2><span>Prices starts from 50 euro</span></h2>

        <h3> BoatPilot ecosystem</h3>
        <p>
          The BoatPilot ecosystem based on this module allows you to use
          features such as:
          <ul className="list">
            <li>
              data output (AIS, depth, speed, wind, etc.) from NMEA’s tools in a
              chartplotter on a mobile device (tablet, phone)
            </li>
            <li>
              installation of additional wireless sensors on the yacht from
              BoatPilot. Starting from the tank sensor and engine temperature up
              to the “blackout” antitheft module.
            </li>
            <li>
              receiving remote telemetry via the Boatpilot Guard application via
              the Internet, with the possibility of setting alerts for any
              parameter change (smart yacht system)
            </li>
            <li>multiplexing data to unlimited number of mobile screens</li>
          </ul>
        </p>
      </div>
      <div className="minislider">
        <Minislider
          names={[
            "plotter_2.jpg",
            "plotter_3.jpg",
            "plotter_4.jpg",
            "plotter_7.jpg",
            "plotter_9.jpg",
            "plotter_10.jpg",
            "plotter_11.jpg",
            "plotter_12.jpg",
            "plotter_13.jpg",
          ]}
        />
      </div>
      <div className="videocontent">
        <Youtube tubeid="vmlTH5y4uDQ" posterimagename="tubeposter_god.jpg" />
      </div>
    </section>
    <section
      data-side="scheme"
      className="contentbox  wrap --fullw --dark --novideo --killtoppadding"
    >
      <div className="textcontent">
        <Svgsprite svgclass="scheme" name={scheme.id} vbox={scheme.viewBox} />
      </div>
    </section>
    <section data-side="specs" className="contentbox wrap --fullw">
      <div className="textcontent">
        <h2>
          Module <span>installation and specs</span>
        </h2>

        <p>
          The module will help to improve navigation safety, make it possible to
          update the navigation system by using the iOs and Android device, and
          help to prevent the hijacking of the yacht or its damage.
        </p>

        <p>
          Leaks, battery discharge, impeller spoilage/motor overheating,
          replacement of liquid level sensors with wireless ones are far from a
          complete list of options provided by the BoatGod module. Installation
          of the module and main sensors does not require special skills and
          additional equipment and takes up to 15 minutes.
        </p>
        <h3>Technical characteristics of the module</h3>
        <ul className="list">
          <li>Network connection type: NMEA 0183/2000</li>
          <li>Power supply: NMEA, 12in external</li>
          <li>Wireless interfaces: LoRa, ZigBee, WiFi, Gsm, 4g LTE</li>
          <li>Current consumption: medium/maximum</li>
          <li>
            Multiplexing/transmitting to: web browser, BoatPilot chartplotter,
            Boatpilot Guard app
          </li>
        </ul>

        <h3>External sensors offered by BoatPilot</h3>
        <ul className="list">
          <li>Leak/temperature sensor</li>
          <li>Battery voltage and temperature sensor</li>
          <li>Engine RPM and temperature sensor</li>
          <li>Tank fluid level and leak sensor</li>
        </ul>
      </div>
      <div className="minislider">
        <Minislider
          names={[
            "god_1.jpg",
            "god_2.jpg",
            "god_3.jpg",
            "god_4.jpg",
            "god_5.jpg",
            "god_6.jpg",
            "god_7.jpg",
          ]}
        />
      </div>
      <div className="videocontent">
        <Youtube tubeid="vmlTH5y4uDQ" posterimagename="tubeposter_god.jpg" />
      </div>
    </section>
    <section
      data-side="why"
      className="contentbox wrap --fullw --dark --novideo"
    >
      <div className="textcontent --max80">
        <h2>
          Why Boatpilot <span>what problems does it solve</span>
        </h2>
        <p>You need a BoatPilot if:</p>
        <ul className="list">
          <li>
            You want to <b>use chartplotter App</b> on your phone or tablet
          </li>
          <li>
            You want to <b>receive information from the NMEA</b> at any point on
            your yacht
          </li>
          <li>
            You want to be calm leaving the yacht in the anchorage,{" "}
            <b>get accurate telemetry</b> (anchor demolition, depth, force of
            wind gusts)
          </li>
          <li>
            You want <b>to be calm when you leave the yacht</b> in the marina
            (theft prevention, several leaks sensors, discharging and warming up
            batteries sensor), yacht intrusion prevention system
          </li>
          <li>
            You want to have <b>confirmation</b> of all your actions{" "}
            <b>for the insurance company or coastguard</b> (black box mode)
          </li>
          <li>
            You want to have an <b>advanced collision prevention system</b>{" "}
            based on your AIS
          </li>
          <li>
            You plan to hire an external skipper or team to deliver your yacht
            for <b>remote control of their actions</b>
          </li>
        </ul>
      </div>
    </section>


    {/* <Orderform typeCharter={false} orderType="private" /> */}
  </Layout>
);

export default IndexPage;
